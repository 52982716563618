<template>
  <div class="grotta-home-book">
    <div class="grotta-home-book__header">
      <h1 class="grotta-home-book__header_title" align="center">
        The Grotta House by Richard Meier
      </h1>
    </div>

    <div class="grotta-home-book__body">
      <span class="grotta-home-book__body_co-authors" align="center">
        text by Joseph Rykwert and David Revere MacFadden introduction by Richard Meier photography
        by Tom Grotta and Scott Frances design by Massimo Vignelli Rizzoli
      </span>
      <img
        class="grotta-home-book__body_main-image"
        v-webp="require('@/assets/images/books/house-book-cover.png')"
        src="@/assets/images/books/house-book-cover.png"
        alt="The Grotta House by Richard Meier"
        decoding="async"
        loading="lazy"
      />

      <section class="details details__container">
        <div class="details__properties">
          <h2 class="details__properties_title">Book Details</h2>
          <div class="details__properties_box">
            <div v-for="(item, index) in bookProperties" :key="`property-${index}`" class="item">
              <span class="item_title">{{ item.title }}:</span>
              <span class="item_value">{{ item.value }}</span>
            </div>
          </div>
        </div>
        <div class="details__description-box">
          <span class="details__description-box_description">
            The Grotta house, designed by Richard Meier in the second half of the 1980s, showcases
            the Grottas' exceptional collection of crafts and furniture. The book includes an
            interview with the Grottas, detailed photography and text by architectural historian
            Joseph Rykwert and David Revere MacFadden, Chief Curator of the Museum of Arts and
            Design in New York.
          </span>
          <div class="details__description-box_buttons-box">
            <BaseButton
              class="button--oval button--white"
              text="Buy Now"
              @click.native="followLink"
            />
            <!-- <BaseButton
              class="button--outline-white button--oval"
              text="Preview Online"
              @click.native="showPreview = true"
            /> -->
            <ShareBar
              class="button--share"
              title="The Grotta House by Richard Meier"
              description="The book includes an
          interview with the Grottas, detailed photography and text by architectural historian
          Joseph Rykwert and David Revere MacFadden, Chief Curator of the Museum of Arts and Design
          in New York."
              :image="bookImage"
            />
          </div>
        </div>
      </section>

      <section class="creators creators__container">
        <h2 class="creators__title">Related Creators</h2>
        <div class="creators__group">
          <span
            v-for="(creator, key) in creators"
            :key="`creator-${key}`"
            class="creators__group_item"
            :class="{ clickable: creator.link }"
            @click="goToArtistPage(creator.link)"
            >{{ creator.lastName }}, {{ creator.firstName }}</span
          >
        </div>
      </section>

      <!-- <section class="reviews reviews__container">
        <h2 class="reviews__title">Reviews</h2>
        <div v-for="(review, index) in reviews" :key="`review-${index}`" class="reviews__item">
          <span class="reviews__item_text">{{ review.text }}</span>
          <span class="reviews__item_author">by {{ review.author }}</span>
        </div>
        <BaseButton
          class="reviews__button button--outline-white button--oval"
          text="Load More"
        />
      </section> -->
    </div>

    <!-- <div class="grotta-home-book__pop-up" :class="{ visible: showPreview }">
      <div class="grotta-home-book__pop-up_header">
        <BaseButton
          class="button--oval button--outline-white"
          text="Exit preview"
          @click.native="showPreview = false"
        />
      </div>

      <iframe
        ref="iframePdf"
        class="pdf-viewer"
        scrolling="no"
        src="https://arnoldsche.com/us/product/the-grotta-home-by-richard-meier/#dflip-df_36920/1/"
      ></iframe>
    </div> -->
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import ShareBar from "@/components/Share.vue";

export default {
  components: { BaseButton, ShareBar },

  data() {
    return {
      //showPreview: false,
      // currentPage: 1,
      // pageCount: 0,
      bookImage: `${process.env.VUE_APP_FRONTEND_URL}/img/books/house-book-cover.webp`,
      bookProperties: [
        { title: "Pages", value: "120 pp" },
        { title: "Size", value: '10.1" x 10.1" x .08"' },
        { title: "Format", value: "Hardcover" },
        { title: "Ilustrations", value: "approx. 300ills" },
        { title: "Language", value: "English" },
        { title: "ISBN", value: "978-3-89790-568-9" },
      ],
      creators: [
        {
          name: "Edgar and Joyce Anderson",
          firstName: "Edgar and Joyce",
          lastName: "Anderson",
          link: "",
        },
        { name: "Robert Arneson", firstName: "Robert", lastName: "Arneson", link: "" },
        { name: "Rudy Autio", firstName: "Rudy", lastName: "Autio", link: "" },
        { name: "Dorothy Gill Barnes", firstName: "Dorothy Gill", lastName: "Barnes", link: "" },
        { name: "Michael Becker", firstName: "Michael", lastName: "Becker", link: "" },
        { name: "Bengt Berglund", firstName: "Bengt", lastName: "Berglund", link: "" },
        { name: "Wendell Castle", firstName: "Wendell", lastName: "Castle", link: "" },
        { name: "Carme Collel", firstName: "Carme", lastName: "Collel", link: "" },
        { name: "Hans Coper", firstName: "Hans", lastName: "Coper", link: "" },
        { name: "Charles Crowley", firstName: "Charles", lastName: "Crowley", link: "" },
        { name: "Richard DeVore", firstName: "Richard", lastName: "DeVore", link: "" },
        { name: "Dominic Di Mare", firstName: "Dominic", lastName: "Di Mare", link: "" },
        { name: "Zhou Ding Fang", firstName: "Zhou", lastName: "Ding Fang", link: "" },
        { name: "Ruth Duckworth", firstName: "Ruth", lastName: "Duckworth", link: "" },
        { name: "Kenneth Ferguson", firstName: "Kenneth", lastName: "Ferguson", link: "" },
        { name: "Viola Frey", firstName: "Viola", lastName: "Frey", link: "" },
        { name: "Edwin & Frank Gehry", firstName: "Edwin & Frank", lastName: "Gehry", link: "" },
        { name: "Mary Giles", firstName: "Mary", lastName: "Giles", link: "" },
        { name: "David Gilhooly", firstName: "David", lastName: "Gilhooly", link: "" },
        { name: "Michael Graves", firstName: "Michael", lastName: "Graves", link: "" },
        { name: "Walter Gropius", firstName: "Walter", lastName: "Gropius", link: "" },
        { name: "Françoise Grossen", firstName: "Françoise", lastName: "Grossen", link: "" },
        { name: "Helena Hernmarck", firstName: "Helena", lastName: "Hernmarck", link: "" },
        { name: "Wayne Higby", firstName: "Wayne", lastName: "Higby", link: "" },
        { name: "Joseph Hoffman", firstName: "Joseph", lastName: "Hoffman", link: "" },
        { name: "Nicholas Homoky", firstName: "Nicholas", lastName: "Homoky", link: "" },
        { name: "Arne Jacobsen", firstName: "Arne", lastName: "Jacobsen", link: "" },
        { name: "Lu Jian Xing", firstName: "Lu", lastName: "Jian Xing", link: "" },
        { name: "Karen Karnes", firstName: "Karen", lastName: "Karnes", link: "" },
        { name: "Noami Kobayashi", firstName: "Noami", lastName: "Kobayashi", link: "" },
        { name: "Henning Koppel", firstName: "Henning", lastName: "Koppel", link: "" },
        { name: "Markku Kosonen", firstName: "Markku", lastName: "Kosonen", link: "" },
        { name: "Gyöngy Laky", firstName: "Gyöngy", lastName: "Laky", link: "" },
        { name: "James Lawton", firstName: "James", lastName: "Lawton", link: "" },
        { name: "Don Lelooksa", firstName: "Don", lastName: "Lelooksa", link: "" },
        { name: "Alexander Lichtveld", firstName: "Alexander", lastName: "Lichtveld", link: "" },
        { name: "David Ling", firstName: "David", lastName: "Ling", link: "" },
        { name: "James Makins", firstName: "James", lastName: "Makins", link: "" },
        { name: "Sam Maloof", firstName: "Sam", lastName: "Maloof", link: "" },
        { name: "Bodil Manz", firstName: "Bodil", lastName: "Manz", link: "" },
        { name: "John McQueen", firstName: "John", lastName: "McQueen", link: "" },
        { name: "Rebecca Medel", firstName: "Rebecca", lastName: "Medel", link: "" },
        { name: "Richard Meier", firstName: "Richard", lastName: "Meier", link: "" },
        { name: "Mary Merkel-Hess", firstName: "Mary", lastName: "Merkel-Hess", link: "" },
        { name: "Norma Minkowitz", firstName: "Norma", lastName: "Minkowitz", link: "" },
        { name: "Mira Nakashima", firstName: "Mira", lastName: "Nakashima", link: "" },
        {
          name: "Otto & Gertrud Natzler",
          firstName: "Otto & Gertrud",
          lastName: "Natzler",
          link: "",
        },
        { name: "Ronna Neuenschwander", firstName: "Ronna", lastName: "Neuenschwander", link: "" },
        { name: "Richard Notkin", firstName: "Richard", lastName: "Notkin", link: "" },
        { name: "Marilyn Hank Otton", firstName: "Marilyn Hank", lastName: "Otton", link: "" },
        { name: "Gustavo Perez", firstName: "Gustavo", lastName: "Perez", link: "" },
        { name: "Mark Pharis", firstName: "Mark", lastName: "Pharis", link: "" },
        { name: "Wendy Ramshaw", firstName: "Wendy", lastName: "Ramshaw", link: "" },
        { name: "Lucie Rie", firstName: "Lucie", lastName: "Rie", link: "" },
        { name: "Mary Rohem", firstName: "Mary", lastName: "Rohem", link: "" },
        { name: "Ed Rossbach", firstName: "Ed", lastName: "Rossbach", link: "" },
        {
          name: "Mariette Rousseau Vermette",
          firstName: "Mariette",
          lastName: "Rousseau Vermette",
          link: "",
        },
        { name: "Norm Sartorius", firstName: "Norm", lastName: "Sartorius", link: "" },
        { name: "Adrian Saxe", firstName: "Adrian", lastName: "Saxe", link: "" },
        { name: "Mary Scheier", firstName: "Mary", lastName: "Scheier", link: "" },
        { name: "Kay Sekimachi", firstName: "Kay", lastName: "Sekimachi", link: "" },
        { name: "Karyl Sisson", firstName: "Karyl", lastName: "Sisson", link: "" },
        { name: "Paul Soldner", firstName: "Paul", lastName: "Soldner", link: "" },
        { name: "Bob Stocksdale", firstName: "Bob", lastName: "Stocksdale", link: "" },
        { name: "Toshiko Takaezu", firstName: "Toshiko", lastName: "Takaezu", link: "" },
        { name: "Lenore Tawney", firstName: "Lenore", lastName: "Tawney", link: "" },
        { name: "Robert Turner", firstName: "Robert", lastName: "Turner", link: "" },
        { name: "Bertil Vallien", firstName: "Bertil", lastName: "Vallien", link: "" },
        { name: "Deborah Valoma", firstName: "Deborah", lastName: "Valoma", link: "" },
        { name: "Peter Voulkos", firstName: "Peter", lastName: "Voulkos", link: "" },
        {
          name: "Gerald and Gotlind Weigel",
          firstName: "Gerald and Gotlind",
          lastName: "Weigel",
          link: "",
        },
        { name: "Hans Weissflog", firstName: "Hans", lastName: "Weissflog", link: "" },
        { name: "Lu Wen Xia", firstName: "Lu", lastName: "Wen Xia", link: "" },
        { name: "Tapio Wirkkala", firstName: "Tapio", lastName: "Wirkkala", link: "" },
        { name: "Beatrice Wood", firstName: "Beatrice", lastName: "Wood", link: "" },
        { name: "Russel Wright", firstName: "Russel", lastName: "Wright", link: "" },
        { name: "William Wyman", firstName: "William", lastName: "Wyman", link: "" },
      ],
      reviews: [
        {
          text: '"The book, “The Grotta House" is an intimate portrait of the union of two individuals, fused into a singular point of view and seen through the lens of the artifacts that have defined their passionate journey. ',
          author: "Josh Owen, Josh Owen LLC",
        },
        {
          text: 'The house itself is a vessel that is teeming with their narrative. Meier’s building deploys lightness in a way which warms the repository and balances it between the experiences of living with and dreaming through the objects it encapsulates."',
          author: "Josh Owen, Josh Owen LLC",
        },
        {
          text: 'Meier’s building deploys lightness in a way which warms the repository and balances it between the experiences of living with and dreaming through the objects it encapsulates."',
          author: "Josh Owen, Josh Owen LLC",
        },
      ],
    };
  },

  head: {
    title: function () {
      return {
        inner: "GH - Book: The Grotta House",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  computed: {
    ...mapGetters("publicArtists", {
      artistsList: "getArtistsList",
    }),
  },

  async created() {
    await this.apiGetArtistsList();

    this.creators.forEach(author => {
      this.artistsList.reduce((previousValue, currentValue) => {
        let name = `${currentValue.first_name} ${currentValue.last_name}`;
        if (name === author.name) {
          author.link = `/creators/${currentValue.slug}`;
        }
      }, []);
    });

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Library",
        to: "/library",
      },
      {
        title: "Books",
        to: "/library/books",
      },
      {
        title: "The Grotta House By Richard Meier",
        to: "/library/the-grotta-house-by-richard-meier",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicArtists", ["apiGetArtistsList"]),

    followLink() {
      window.open("https://store.browngrotta.com/the-grotta-house-by-richard-meier/", "_blank");
    },

    goToArtistPage(path) {
      this.$router.push({ path: path });
    },

    // slidePdf(direction) {
    //   if (direction === "next" && this.currentPage < this.pageCount) {
    //     this.currentPage++;
    //   }
    //   if (direction === "prev" && this.currentPage > 1) {
    //     this.currentPage--;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.grotta-home-book {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $lightBlack;
  padding: 26.2rem 20.83rem 22.25rem;
  @media only screen and (max-width: $md) {
    padding: 26.2rem 10rem 22.25rem;
  }
  @media only screen and (max-width: $xs) {
    padding: 28.3rem 2.8rem 16rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
      font-size: 62px;
      line-height: 1.1;
      font-family: $fontBodoni;
      @media only screen and (max-width: $xs) {
        font-size: 39px;
        line-height: 1.28;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_co-authors {
      width: 55%;
      font-size: 16px;
      line-height: 1.38;
      margin-top: 64px;
      @media only screen and (max-width: $md) {
        width: 100%;
      }
      @media only screen and (max-width: $xs) {
        font-size: 14px;
      }
    }

    &_main-image {
      margin-top: 30px;
    }

    .details {
      &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 74px;
      }

      &__properties {
        max-width: 30%;
        @media only screen and (max-width: $md) {
          width: 100%;
          max-width: 100%;
        }
        &_title {
          font-size: 43px;
          line-height: 1.21;
          font-family: $fontBodoni;
          @media only screen and (max-width: $xs) {
            font-size: 30px;
            line-height: 1.33;
            text-align: center;
          }
        }
        &_box {
          margin-top: 38px;
          @media only screen and (max-width: $md) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
          }
          @media only screen and (max-width: $sm) {
            grid-template-columns: repeat(2, 1fr);
          }
          .item {
            display: flex;
            margin-top: 12px;
            @media only screen and (max-width: $md) {
              flex-direction: column;
            }
            @media only screen and (max-width: $xs) {
              align-items: center;
              margin-top: 18px;
            }
            &_title {
              width: 140px;
              font-size: 18px;
              line-height: 2.44;
              font-weight: 500;
              @media only screen and (max-width: $xs) {
                width: max-content;
              }
            }
            &_value {
              font-size: 16px;
              line-height: 2.75;
            }
          }
        }
      }

      &__description-box {
        max-width: 68%;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: $md) {
          max-width: 100%;
        }

        &_description {
          font-size: 21px;
          line-height: 1.66;
          text-align: justify;
          margin-bottom: 40px;
          @media only screen and (max-width: $xs) {
            margin: 50px 0px;
            font-size: 18px;
            line-height: 1.89;
          }
        }
        &_buttons-box {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          @media only screen and (max-width: $sm) {
            flex-direction: column;
          }

          .button {
            &--white {
              @media only screen and (max-width: $sm) {
                margin: 35px 0px;
              }
            }
            &--share {
              width: 140px;
              margin-left: 24px;
              @media only screen and (max-width: $sm) {
                width: max-content;
                margin-left: 0px;
                margin-bottom: 24px;
              }
            }
          }
        }
      }
    }

    .creators {
      &__container {
        display: flex;
        flex-direction: column;
        margin-top: 74px;
        width: 100%;
      }

      &__title {
        font-size: 43px;
        line-height: 1.21;
        font-family: $fontBodoni;
        @media only screen and (max-width: $xs) {
          font-size: 30px;
          line-height: 1.33;
          text-align: center;
        }
      }

      &__group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 81rem;
        margin-top: 38px;
        @media only screen and (max-width: $xxl) {
          height: 101rem;
        }
        @media only screen and (max-width: $xxl) {
          height: 121rem;
        }
        @media only screen and (max-width: $sm) {
          align-items: center;
          height: max-content;
          width: 100%;
          margin-top: 4.6rem;
          margin-left: 0%;
        }

        &_item {
          width: 29%;
          font-size: 21px;
          line-height: 1.86;
          @media only screen and (max-width: $xxl) {
            width: 28%;
          }
          @media only screen and (max-width: $sm) {
            font-size: 18px;
            line-height: 1.89;
            width: max-content;
          }

          &.clickable:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    // .reviews {
    //   &__container {
    //     display: flex;
    //     flex-direction: column;
    //     margin-top: 74px;
    //   }

    //   &__title {
    //     font-size: 43px;
    //     line-height: 1.21;
    //     font-family: $fontBodoni;
    //     margin-bottom: 5px;
    //     @media only screen and (max-width: $xs) {
    //       font-size: 30px;
    //       line-height: 1.33;
    //       text-align: center;
    //     }
    //   }

    //   &__item {
    //     display: flex;
    //     flex-direction: column;
    //     padding: 35px 0px;
    //     border-bottom: 1px solid $white;

    //     &:last-of-type {
    //       border-bottom: none;
    //     }

    //     &_text {
    //       font-style: italic;
    //       font-size: 21px;
    //       line-height: 1.66;
    //       @media only screen and (max-width: $xs) {
    //         font-size: 18px;
    //         line-height: 1.8;
    //       }
    //     }

    //     &_author {
    //       font-size: 18px;
    //       line-height: 2.44;
    //       margin-top: 12px;
    //       @media only screen and (max-width: $xs) {
    //         font-size: 16px;
    //         line-height: 2.75;
    //       }
    //     }
    //   }

    //   &__button {
    //     font-family: $fontBodoni;
    //   }
    // }
  }

  // &__pop-up {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   height: 100vh;
  //   width: 100%;
  //   background-color: #767676;
  //   opacity: 0;
  //   transform: scale(0);
  //   pointer-events: none;
  //   transition: all 0.5s;
  //   z-index: 999;

  //   &.visible {
  //     opacity: 1;
  //     pointer-events: all;
  //     transform: scale(1);
  //     transition: all 0.5s;
  //   }

  //   .button--oval {
  //     font-size: 1.7rem;
  //     color: $white;
  //     background-color: #767676;
  //     margin-top: 0rem;
  //     margin-right: 1rem;

  //     &:hover {
  //       background-color: #767676;
  //     }
  //   }

  //   &_header {
  //     display: flex;
  //     justify-content: flex-end;
  //     align-items: center;
  //     height: 10rem;
  //     width: 100%;
  //   }

  //   .pdf-viewer {
  //     height: 85%;
  //     width: 100%;
  //   }
  // }
}
</style>
